// import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import MuiRating from '@mui/material/Rating';

import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  ({ palette }) => ({
    root: { color: palette.yellow.main },
    iconEmpty: { color: palette.grey[300] },
    empty: { color: palette.yellow.main },
  }),
  { name: 'Rating' },
);

export default function Rating(props) {
  const { defaultEmptyIcon, ...rest } = props;
  const classes = useStyles(props);

  return (
    <MuiRating
      {...rest}
      classes={{
        root: classes.root,
        iconEmpty: defaultEmptyIcon ? classes.iconEmpty : classes.empty,
      }}
      emptyIcon={<FontAwesomeIcon fixedWidth icon={faStar} />}
      icon={<FontAwesomeIcon fixedWidth icon={faStarSolid} />}
    />
  );
}

Rating.propTypes = {
  defaultEmptyIcon: PropTypes.bool,
};
