import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { useDialogStateManager, useStandardApiRequest } from 'my-core/hooks';
import { testimonialsHomepage } from 'my-core/landing-data';

import HorizontalStepIndicator from 'my-components/HorizontalStepIndicator';
import ModalVideo from 'my-components/ModalVideo';
import Rating from 'my-elements/Rating';
import { Container } from 'my-layout';

import Typography from '@mui/material/Typography';

import { faPlayCircle, faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchOnlineCourseFeedbackStats } from 'my-actions/OnlineCourseFeedbackActions';

const useStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, transitions }) => ({
    root: {
      paddingTop: spacing(10),
      paddingBottom: spacing(10),
    },
    testimonial: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: spacing(7),
      alignItems: 'center',
    },
    quote: {
      flex: '1 1 auto',
      minHeight: 390,
    },
    quoteIcon: { color: palette.primary.main, marginBottom: spacing(1) },
    rating: { marginTop: spacing(2) },

    videoWrapper: {
      flexShrink: 0,
      width: '55%',
      marginLeft: spacing(5),
    },
    videoContainer: {
      position: 'relative',
      flex: '0 0 auto',
      width: '100%',
      paddingBottom: '63%',
      borderRadius: shape.borderRadiusSm,
      overflow: 'hidden',
      cursor: 'pointer',
      '& img': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        objectFit: 'cover',
      },
      '&:hover $playButton': {
        '& $playButton ': {
          opacity: 1,
        },
      },
    },
    playButton: {
      fontSize: '2.5em',
      opacity: 0.8,
      transition: transitions.create('opacity', { duration: 300 }),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: palette.common.white,
    },
    card: {
      position: 'absolute',
      bottom: 0,
      left: '5%',
      minWidth: '40%',
      maxWidth: '90%',
      padding: spacing(2),
      borderRadius: [[shape.borderRadiusSm, shape.borderRadiusSm, 0, 0]],
      backgroundColor: palette.grey[800],
      color: palette.common.white,
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
      overflow: 'hidden',
    },

    [breakpoints.down('md')]: {
      root: {
        paddingTop: spacing(5),
        paddingBottom: spacing(5),
      },
      testimonial: {
        flexDirection: 'column-reverse',
        marginTop: spacing(2),
      },
      videoWrapper: {
        width: '100%',
        margin: spacing(0, 0, 4),
      },
      quote: {
        width: '100%',
        minHeight: 180,
      },
      stepsIndicator: {
        marginTop: spacing(3),
        display: 'flex',
        justifyContent: 'center',
      },
    },
    [breakpoints.down('sm')]: {
      playButton: {
        fontSize: '2em',
      },
      card: {
        padding: spacing(1, 2),
      },
      quote: {
        minHeight: 230,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      quoteIcon: {
        alignSelf: 'flex-start',
      },
    },
  }),
  {
    name: 'StudentTestimonials',
  },
);

const STATS_FETCH_KEY = 'landing_page_feedback_stats';

function StudentTestimonials({ header = 'Student Success Stories', subheader, testimonials = testimonialsHomepage }) {
  const classes = useStyles();

  const { performRequest: performFetchFeedbackStats, requestStatus: fetchFeedbackStatsStatus } = useStandardApiRequest({
    actionCreator: fetchOnlineCourseFeedbackStats,
    requestKey: STATS_FETCH_KEY,
  });
  useEffect(() => {
    if (!fetchFeedbackStatsStatus && !subheader) {
      performFetchFeedbackStats(undefined, STATS_FETCH_KEY);
    }
  }, [fetchFeedbackStatsStatus, performFetchFeedbackStats, subheader]);
  const feedbackStats = fetchFeedbackStatsStatus?.stats;

  const [playerOpen, playerItem, playerActions] = useDialogStateManager();

  const [activeTestimonialIdx, setActiveTestimonialIdx] = useState(0);

  const testimonialItem = testimonials[activeTestimonialIdx];
  const { author, gradeLevel, imageSrc, testimonial } = testimonialItem;

  return (
    <>
      <Container className={classes.root}>
        <Typography align="center" component="h2" gutterBottom typography={{ xs: 'h5', sm: 'h3' }}>
          {header}
        </Typography>
        <Typography align="center" typography={{ xs: 'body1', sm: 'body3' }}>
          {subheader ||
            `Over ${(Math.floor((feedbackStats?.total_ratings || 1900) / 100) * 100).toLocaleString()} reviews with a ${
              feedbackStats?.average_rating
            }/5 average rating`}
        </Typography>

        <div className={classes.testimonial}>
          <div className={classes.quote}>
            <FontAwesomeIcon className={classes.quoteIcon} icon={faQuoteLeft} size="2x" />
            <Typography component="p" gutterBottom typography={{ xs: 'subtitle2', sm: 'h5' }}>
              {testimonial}
            </Typography>
            <Rating className={classes.rating} readOnly value={5} />
          </div>

          <div className={classes.videoWrapper}>
            <div
              className={classes.videoContainer}
              onClick={() => {
                landingPageCTAClicked('student_testimonials', 'play_video', author);
                playerActions.open(testimonialItem);
              }}
            >
              <img alt={author} src={imageSrc} />
              <div className={classes.card}>
                <Typography variant="subtitle1">{author}</Typography>
                <Typography color="textSecondary" variant="body1">
                  {gradeLevel}
                </Typography>
              </div>
              <div className={classes.playButton}>
                <FontAwesomeIcon className={classes.playButton} icon={faPlayCircle} />
              </div>
            </div>
          </div>
        </div>
        <HorizontalStepIndicator
          activeItemIndex={activeTestimonialIdx}
          className={classes.stepsIndicator}
          onChangeActiveItemIndex={(idx, btn) => {
            landingPageCTAClicked('student_testimonials', 'stepper', btn === 'dot' ? `dot_${idx + 1}` : btn);
            setActiveTestimonialIdx(idx);
          }}
          stepCount={testimonials.length}
        />
      </Container>

      {playerItem && (
        <ModalVideo
          className={classes.modalVideo}
          onClose={playerActions.close}
          open={!!playerOpen}
          PlayerProps={{
            videoId: `landing-page-student-stories-${playerItem.author}`,
            urls: { hls: playerItem.videoSrc.hls, original: playerItem.videoSrc.original },
          }}
        />
      )}
    </>
  );
}

export default StudentTestimonials;
