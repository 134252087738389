import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { useCurrentUser, useDialogStateManager } from 'my-core/hooks';
import { entities } from 'my-core/my-utils';
import { useCurrentUserSubscription } from 'my-core/subscription-utils';

import SubscriptionCheckout from 'my-components/SubscriptionCheckout';
import CustomColorButton from 'my-elements/CustomColorButton';
import { Container } from 'my-layout';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { toggleLoginForm } from 'my-actions/AppStateActions';

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    root: {
      minHeight: 300,
      paddingTop: spacing(12),
      paddingBottom: spacing(12),
      textAlign: 'center',
    },
    root_primary: {
      backgroundColor: palette.background.primary,
      color: palette.common.white,
    },
    container: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
    title: { marginBottom: spacing(2) },
    buttons: { marginTop: spacing(4) },
    button: {
      padding: spacing(1, 3),
      borderRadius: 99,
    },

    [breakpoints.only('xs')]: {
      root: {
        paddingTop: spacing(8),
        paddingBottom: spacing(8),
      },
      buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& > * + *': { marginTop: spacing(2) },
      },
    },
    [breakpoints.up('sm')]: {
      buttons: {
        marginTop: spacing(6),

        '& > * + *': { marginLeft: spacing(2) },
      },
    },
  }),
  { name: 'LandingPageGetStarted' },
);

function LandingPageGetStarted(props) {
  const {
    action,
    color,
    header = `Start Learning with Wizeprep${entities.noBreakSpace}Today`,
    page = 'homepage',
    primaryButtonProps,
    secondaryButtonProps,
    subheader = `Get instant access to study help made for your course when you${entities.noBreakSpace}sign${entities.noBreakSpace}up.`,
  } = props;
  const classes = useStyles(props);

  const currentUser = useCurrentUser();

  const subscription = useCurrentUserSubscription();
  const [subCheckoutOpen, subCheckoutOpened, subCheckoutActions] = useDialogStateManager();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handlePrimaryButtonClick(e) {
    landingPageCTAClicked('start_learning_banner', e.target.innerText.trim().toLowerCase().replaceAll(' ', '_'));
    if (currentUser.guest) {
      return dispatch(
        toggleLoginForm({
          page: 'signup',
          source: `${page}_start_learning_banner`,
        }),
      );
    }

    if (subscription) {
      return navigate('/');
    }
    subCheckoutActions.open(true);
  }

  return (
    <section className={classNames(classes.root, classes[`root_${color}`])}>
      <Container className={classes.container}>
        <Typography align="center" gutterBottom typography={{ xs: 'h5', sm: 'h3' }} whiteSpace="pre-line">
          {header}
        </Typography>
        <Typography align="center" maxWidth={700}>
          {subheader}
        </Typography>
        {action || (
          <div className={classes.buttons}>
            <CustomColorButton
              // eslint-disable-next-line @eslint-react/no-children-prop
              children={
                currentUser.guest ? 'Start Learning Free'
                : subscription ?
                  'My Dashboard'
                : 'Subscribe Now'
              }
              className={classes.button}
              color={color ? `white_${color}` : 'primary'}
              onClick={handlePrimaryButtonClick}
              size="large"
              variant="contained"
              {...primaryButtonProps}
            />
            {secondaryButtonProps && <Button className={classes.button} variant="outlined" {...secondaryButtonProps} />}
          </div>
        )}
      </Container>
      {subCheckoutOpened && <SubscriptionCheckout onClose={subCheckoutActions.close} open={subCheckoutOpen} />}
    </section>
  );
}

LandingPageGetStarted.propTypes = {
  action: PropTypes.node,
  primaryButtonProps: PropTypes.object,
  secondaryButtonProps: PropTypes.object,
  header: PropTypes.string,
  page: PropTypes.string,
  subheader: PropTypes.string,
};
export default LandingPageGetStarted;
