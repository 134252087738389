import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';

import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(
  () => ({
    arrowIcon: { fontSize: '0.7em' },
    stepIcon: { fontSize: '0.5em' },
    step: { opacity: 0.5 },
    step_active: { opacity: 1 },
  }),
  { name: 'HorizontalStepIndicator' },
);

function HorizontalStepIndicator(props) {
  const { activeItemIndex, className, onChangeActiveItemIndex, stepCount } = props;
  const classes = useStyles(props);

  const handleClickLeftButton = () => {
    onChangeActiveItemIndex((activeItemIndex - 1 + stepCount) % stepCount, 'left');
  };

  const handleClickRightButton = () => {
    onChangeActiveItemIndex((activeItemIndex + 1) % stepCount, 'right');
  };

  return (
    <div className={className}>
      <IconButton color="inherit" onClick={handleClickLeftButton} size="small">
        <FontAwesomeIcon className={classes.arrowIcon} icon={faArrowLeft} />
      </IconButton>

      {[...Array(stepCount)].map((_, idx) => (
        <IconButton
          key={idx}
          className={classNames(classes.step, { [classes.step_active]: activeItemIndex === idx })}
          color="inherit"
          onClick={() => onChangeActiveItemIndex(idx, 'dot')}
          size="small"
          // color={activeItemIndex === idx ? 'primary' : 'default'}
        >
          <FontAwesomeIcon className={classes.stepIcon} icon={faCircle} />
        </IconButton>
      ))}
      <IconButton color="inherit" onClick={handleClickRightButton} size="small">
        <FontAwesomeIcon className={classes.arrowIcon} icon={faArrowRight} />
      </IconButton>
    </div>
  );
}

HorizontalStepIndicator.propTypes = {};
export default HorizontalStepIndicator;
