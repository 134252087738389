import standardApiRequestAction from 'my-core/standardApiRequestAction';

export function fetchOnlineCourseFeedbacksApi(fetchParams, requestKey, page) {
  return standardApiRequestAction({
    path: '/api/v1/online_course_feedbacks',
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function fetchOnlineCourseFeedbackStats(filter, requestKey) {
  return standardApiRequestAction({
    path: '/api/v1/online_course_feedbacks/stats',
    body: { ...filter, static: true }, // static=true causes Cloudflare custom page rule to cache response
    requestKey,
  });
}

export function createOnlineCourseFeedbackApi(body, onlineCourseId) {
  return standardApiRequestAction({
    path: `/api/v1/online_courses/${onlineCourseId}/feedbacks`,
    method: 'post',
    body,
  });
}
export function updateOnlineCourseFeedbackApi(id, body) {
  return standardApiRequestAction({
    path: `/api/v1/online_course_feedbacks/${id}`,
    method: 'patch',
    body,
  });
}

export function fetchOnlineCourseFeedbacksByInstructor({ instructor_id, ...fetchParams }, requestKey, page) {
  return standardApiRequestAction({
    path: `/api/v1/online_course_feedbacks/by_instructor/${instructor_id}`,
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}

export function fetchOCOnlineCourseFeedbacks({ online_course_id, ...fetchParams }, requestKey, page) {
  return standardApiRequestAction({
    path: `/api/v1/online_courses/${online_course_id}/feedbacks`,
    body: fetchParams,
    parentRequestKey: requestKey,
    requestKey: page,
  });
}
