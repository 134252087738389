import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Rating from 'my-elements/Rating';

import Typography from '@mui/material/Typography';

gsap.registerPlugin(ScrollToPlugin);

export default function TestimonialsCarousel({ className, testimonials = TESTIMONIALS }) {
  const rootRef = useRef();
  const [setRef, inView] = useInView({ triggerOnce: true, rootMargin: '-150px 0px' });
  const [paused, setPaused] = useState(false);
  useEffect(() => {
    const el = rootRef.current;
    if (paused || !el || !inView) return;
    const end = el.scrollWidth - el.clientWidth;
    const distance = Math.max(end - el.scrollLeft, 0);
    const tween = gsap.to(el, {
      ease: 'linear',
      duration: distance / 30,
      scrollTo: { x: end, autoKill: true },
    });
    return () => tween.kill();
  }, [paused, inView]);
  return (
    <section
      className={className}
      css={({ breakpoints, palette, shadows, shape, spacing, typography }) => ({
        padding: spacing(10, 0),
        display: 'flex',
        overflowX: 'auto',
        MsOverflowStyle: 'none', // IE and Edge
        scrollbarWidth: 'none', // Firefox
        '&::-webkit-scrollbar': { display: 'none' }, //Chrome, Safari and Opera
        '& > .testimonial': {
          padding: spacing(2),
          margin: spacing(0, 3),
          flex: '0 0 400px',
          maxWidth: '90%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: palette.background.paper,
          borderRadius: shape.borderRadiusSm,
          boxShadow: shadows.states.static,
          '& > .testimonial-content': { flex: 1, display: 'flex', alignItems: 'center', paddingBottom: spacing(2) },
        },
        [breakpoints.down('md')]: {
          padding: spacing(6, 0),
          '& .testimonial': {
            margin: spacing(0, 2),
            '& > .testimonial-content': {
              paddingBottom: spacing(1),
              '& > p': { ...typography.body1 },
            },
          },
        },
      })}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
      ref={el => {
        setRef(el);
        rootRef.current = el;
      }}
    >
      {testimonials.map((t, idx) => (
        <div key={idx} className="testimonial">
          <div className="testimonial-content">
            <Typography align="center" variant="body3">
              {t.testimonial}
            </Typography>
          </div>
          <Typography align="center" mb={0.5} variant="subtitle2">
            {t.author}
          </Typography>
          {t.gradeLevel && (
            <Typography align="center" mb={0.5} variant="body2">
              {t.gradeLevel}
            </Typography>
          )}
          <Rating readOnly size="small" value={5} />
        </div>
      ))}
    </section>
  );
}

const TESTIMONIALS = [
  {
    author: 'Harman',
    gradeLevel: 'University Student at Western',
    testimonial:
      'So helpful in explaining concepts, definitely made me feel 100% more confident about my exam. The perfect studying tool.',
  },
  {
    author: 'Rycon',
    gradeLevel: 'University Student at U of A',
    testimonial:
      'Wizeprep’s course-specific videos and practice questions helped me to get through University Calculus.',
  },
  {
    author: 'Lariah',
    gradeLevel: 'University Student at UBC',
    testimonial:
      'Everything taught is incredibly clear and concise, and allows me to understand topics more in depth. I would 100% recommend to a friend!',
  },
  {
    author: 'Eisha',
    gradeLevel: 'High School Student',
    testimonial:
      'It is unexpectedly identical to the content that I am learning in school. It feels like each lesson is custom-made for me.',
  },
  {
    author: 'Bianca ',
    gradeLevel: 'University Student at McMaster',
    testimonial: 'Wizeprep explains concepts in a very concise way that is really easy to understand.',
  },
  {
    author: 'Emily ',
    gradeLevel: 'University Student at U of A',
    testimonial:
      'I feel so much more confident when taking my exams, and the extra practice questions truly help me understand the material instead of just memorizing it. ',
  },
  {
    author: 'Sarab',
    gradeLevel: 'University Student at Western',
    testimonial:
      'Wizeprep taught me everything I needed to know. The instructor summarized everything clearly and showed us how to tackle many different exam questions.',
  },
  {
    author: 'Ethel ',
    gradeLevel: 'High School Student',
    testimonial:
      'Not only does Wizeprep provide clear and concise lessons for my courses, but I have access to these lessons 24/7 and can always revisit them when needed!',
  },
  {
    author: 'Gurveer',
    gradeLevel: 'University Student at U of T',
    testimonial:
      'Wizeprep further explains the material taught by professors in lectures in simple and understandable terms.',
  },
  {
    author: 'Sarah',
    gradeLevel: 'University Student at Western',
    testimonial:
      'Wizeprep made math seem very easy! It was thorough and simplified everything. Wizeprep is the reason why I passed my exam!',
  },
  {
    author: 'Ayash',
    gradeLevel: 'University Student',
    testimonial:
      'Explains super abstract concepts like vector spaces super smoothly. Actually makes learning those concepts fun to learn rather than tedious.',
  },
  {
    author: 'Sikora',
    gradeLevel: 'High School Student',
    testimonial:
      "Wizeprep makes great analogies to help me understand concepts a lot easier. Since using Wizeprep I've been able to do my math tests and homework with little error. ",
  },
  {
    author: 'Kritika',
    gradeLevel: 'University Student at McMaster',
    testimonial:
      'Explains examples and course material in detail! From start to finish, everything is clearly explained.',
  },
  {
    author: 'Mark',
    gradeLevel: 'University Student at Ryerson',
    testimonial:
      'So helpful, easy to remember things and to learn concepts. No complicated explanations, just things that make sense.',
  },
  {
    author: 'Hossein',
    gradeLevel: 'University Student',
    testimonial: 'Explains the concepts in plain English and really helped me get a better grade.',
  },
  {
    author: 'Norhan',
    gradeLevel: 'High School Student',
    testimonial:
      'Very thorough explanations with many examples and ways to check if answers are correct. Definitely recommend!',
  },
  {
    author: 'Sergio',
    gradeLevel: 'High School Student',
    testimonial: 'Great thorough explanation of problems. Easy to follow!',
  },
  {
    author: 'Anna',
    gradeLevel: 'High School Student',
    testimonial:
      'Clear explanations making it easy to understand. The use of graphs with models offers good visuals for a visual learner.',
  },
  {
    author: 'Heny',
    gradeLevel: 'High School Student',
    testimonial: 'I love it!! I was struggling with trigonometry and this really helped.',
  },
];
