import PropTypes from 'prop-types';

function ProcessedImg(props) {
  const { height, options, src, width, ...rest } = props;
  const imgProps = { width, height, ...rest };
  if (__DEV__ || !src) return <img src={src} {...imgProps} />;
  return (
    <img
      src={`/cdn-cgi/image/${Object.entries({
        width,
        height,
        format: 'auto',
        fit: 'scale-down',
        ...options,
      })
        .filter(([, v]) => !!v)
        .map(e => e.join('='))
        .join(',')}/${src}`}
      {...imgProps}
    />
  );
}

ProcessedImg.propTypes = {
  src: PropTypes.string,
  options: PropTypes.object,
};

export default ProcessedImg;
